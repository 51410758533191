/* eslint-disable vue/no-use-v-if-with-v-for */
<template>
  <div class="container global-list-main-container">
    <div class="upper-global-title">
      <span class=""> Lista de precios </span>
    </div>
    <div class="upper-global-hint">
      <b-icon icon="lightbulb-outline" type="is-primary"> </b-icon>
      <span class="global-form-hint-color"> Recuerda: </span> {{ hint }}
    </div>
    <b-field grouped group-multiline class="global-lists-controls">
      <div class="control">
        <b-button type="is-primary" @click="openAddPriceLists()"
          >Agregar lista de precios</b-button
        >
      </div>
      <div class="control">
        <b-button
          type="is-primary"
          @click="startImport()"
          v-if="
            companyPaymentPlanModules &&
            companyPaymentPlanModules.main.importPricesLists === 'S' &&
            permissions &&
            permissions.main.importPricesLists === 'S'
          "
          >Importar Listas de precios</b-button
        >
      </div>
      <div class="control">
        <b-button
          type="is-primary"
          @click="startExport()"
          v-if="
            companyPaymentPlanModules &&
            companyPaymentPlanModules.main.exportPricesLists === 'S' &&
            permissions &&
            permissions.main.exportPricesLists === 'S'
          "
          >Exportar listas de precios</b-button
        >
      </div>
    </b-field>

    <div class="global-lists-search">
      <div class="global-lists-filters-container"></div>
      <div class="global-lists-filters-columns">
        <b-checkbox
          v-for="singleColumn in columns"
          v-bind:key="singleColumn.label"
          v-model="singleColumn.display"
        >
          {{ singleColumn.label }}
        </b-checkbox>
      </div>
    </div>

    <div class="global-list-main-container">
      <div class="table-header">
        Cantidad de Listas de precios: <b>{{ priceLists.length }}</b>
      </div>
      <b-table
        pagination-position="both"
        :data="priceLists"
        :striped="true"
        :bordered="true"
        :hoverable="true"
        ref="Brands"
        :checked-rows.sync="checkedPricesLists"
        paginated
        :current-page="currentPage"
        :per-page="perPage"
        :opened-detailed="defaultOpenedDetails"
        :selected.sync="selected"
        @details-open="
          (row) =>
            $buefy.toast.open({
              message: `Información de ${row.CLAVE_LISTAP}`,
              type: 'is-success',
              position: 'is-bottom',
            })
        "
        detailed
        detail-key="_id"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        default-sort-direction="asc"
        checkbox-position="left"
        default-sort="CLAVE_LISTAP"
      >
        <template #empty>
          <div class="has-text-centered">No hay listas de precios</div>
        </template>

        <template slot-scope="props">
          <b-table-column
            v-for="column in columns"
            searchable
            sortable
            v-bind="column"
            :key="column.field"
            :visible="column.display"
            :label="column.label"
          >
            <span v-if="!checkIfValid(props.row[column.field])">{{
              "Sin información"
            }}</span>

            <span> {{ props.row[column.field] }}</span>
          </b-table-column>
        </template>

        <template slot="detail" slot-scope="props">
          <article class="media">
            <figure class="media-left">
              <p class="image is-64x64"></p>
            </figure>
            <div class="content">
              <AddPricesListModal :priceListInformation="props.row" />
            </div>
          </article>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import ImportFileModal from "../../Global/components/ImportFileModal";
import AddPricesListModal from "./AddPricesListModal";
import Cleave from "cleave.js";
import Masks from "../../../data/cleave-masks";
import { validateFields, checkValidationErrors } from "../../../utils/fns";

// Cleave variable initialization to bind formats to inputs
const cleave = {
  name: "cleave",
  bind(el, binding) {
    const input = el.querySelector("input");
    input._vCleave = new Cleave(input, binding.value);
  },
  unbind(el) {
    const input = el.querySelector("input");
    input._vCleave.destroy();
  },
};
// @ is an alias to /src
export default {
  name: "PricesLists",
  components: {
    AddPricesListModal,
  },
  directives: { cleave },
  data() {
    return {
      perPage: 50,
      checkedPricesLists: [],
      selected: {},
      currentPage: 1,
      defaultOpenedDetails: [],
      columns: [
        {
          field: "CLAVE_LISTAP",
          label: "Clave de la lista de precios",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "DESCRIPCION",
          label: "Descripción",
          sortable: true,
          searchable: true,
          display: true,
        },
      ],
      masks: Masks,
      brandRequiredErrors: {
        CLAVE_MARC: false,
        DESC_MARC: false,
      },
    };
  },
  async created() {},
  mounted() {
    this.$store.dispatch("CHANGEACTIVE", "pricesLists");
    if (
      this.companyPaymentPlanModules &&
      this.companyPaymentPlanModules.sales.getPriceListsMargin === "S" &&
      this.permissions &&
      this.permissions.sales.getPriceListsMargin === "S"
    ) {
      this.columns.push({
        field: "formatedUtility",
        label: "Porcentaje de utilidad",
        sortable: true,
        searchable: true,
        display: true,
        customSort: this.sortyByKey,
      });
    }
  },
  methods: {
    openAddPriceLists() {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AddPricesListModal,
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    startImport() {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: ImportFileModal,
        props: {
          importType: "priceLists",
          nameImport: "Listas de precios",
          afterAction: "GETPRICESLISTS",
        },
        hasModalCard: false,
        customClass: "import-provider-modal-class",
        trapFocus: true,
        width: "50%",
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    startExport() {
      this.$buefy.dialog.confirm({
        title: "Confirmar exportación de clientes",
        message: `¿Desea confirmar la exportación de los clientes a un archivo csv?`,
        cancelText: "Cancelar",
        confirmText: "Confirmar",
        type: "is-success",
        onConfirm: () => this.exportData(),
      });
    },
    exportData() {
      let payload = {
        data: this.priceLists.map((singlePriceList) => {
          const singleClientFormatted = {};

          singleClientFormatted["CLAVE DE LISTA DE PRECIOS (Requerido)"] =
            singlePriceList.CLAVE_LISTAP;
          singleClientFormatted["DESCRIPCION (Requerido)"] =
            singlePriceList.DESCRIPCION;
          singleClientFormatted[
            "PORCENTAJE UTILIDAD PREDETERMINADO (Requerido)"
          ] = singlePriceList.PORC_UTILID;

          return singleClientFormatted;
        }),
        name: "listas-de-precios-" + moment().format("l").toString(),
      };
      this.$store.dispatch("EXPORTCSV", payload);
    },
    sortyByKey(a, b, isAsc) {
      if (isAsc) {
        return a.formatedUtility.localeCompare(b.formatedUtility, undefined, {
          numeric: true,
          sensitivity: "base",
        });
      } else {
        return b.formatedUtility.localeCompare(a.formatedUtility, undefined, {
          numeric: true,
          sensitivity: "base",
        });
      }
    },
  },
  watch: {
    selected(val) {
      if (val != null) {
        if (this.defaultOpenedDetails.includes(val.id)) {
          const index = this.defaultOpenedDetails.indexOf(val.id);
          this.defaultOpenedDetails.splice(index, 1);
        } else {
          this.defaultOpenedDetails.push(val.id);
        }
      }
    },
    defaultOpenedDetails() {
      this.selected = null;
    },
  },
  computed: {
    hint() {
      return this.$store.getters.HINT;
    },
    priceLists() {
      return this.$store.getters.PRICES_LISTS.map((singlePriceList) => {
        let singlePriceListWithProps = { ...singlePriceList };
        singlePriceListWithProps.formatedUtility =
          singlePriceListWithProps.PORC_UTILID + "%";
        return singlePriceListWithProps;
      });
    },
    companyPaymentPlanModules() {
      if (
        this.$store.getters.COMPANY_INFORMATION &&
        this.$store.getters.COMPANY_INFORMATION.paymentPlan
      ) {
        return this.$store.getters.COMPANY_INFORMATION.paymentPlan.modules;
      }
      return this.$store.getters.COMPANY_INFORMATION.paymentPlan.modules;
    },
    permissions() {
      if (this.$store.getters.USER.permissions) {
        return this.$store.getters.USER.permissions.permissions;
      }
      return this.$store.getters.USER.permissions;
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
