<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title" v-if="!priceListInformation">
      Agregar lista de precios
    </h1>

    <div v-if="!priceListInformation" class="global-form-hint-container">
      <p class="global-form-hint">Los campos requeridos tienen el marco</p>
      <span class="global-form-hint-color"> Morado </span>
    </div>

    <h3 class="form-division">Información general</h3>

    <b-field grouped class="special-margin">
      <b-field
        label="Nombre de la lista de precios (Requerido)"
        expanded
        label-position="inside"
        :type="{ 'is-danger': pricesListRequiredErrors.CLAVE_LISTAP }"
        :message="{
          'El nombre de la lista de precios no es valido':
            pricesListRequiredErrors.CLAVE_LISTAP,
        }"
      >
        <b-input
          placeholder="Ejemplo: Marca 1"
          expanded
          icon="numeric"
          v-model="newPricesList.CLAVE_LISTAP"
          required
        ></b-input>
      </b-field>
      <b-field
        label="Descripción de la lista de precios (Requerido)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: Descripción de la marca 1"
          expanded
          icon="clipboard"
          v-model="newPricesList.DESCRIPCION"
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        expanded
        label-position="inside"
        :type="{ 'is-danger': pricesListRequiredErrors.PORC_UTILID }"
        :message="{
          'El porcentaje de utilidad predeterminado no es valido':
            pricesListRequiredErrors.PORC_UTILID,
        }"
        v-if="
          (companyPaymentPlanModules &&
            companyPaymentPlanModules.sales.getPriceListsMargin === 'S' &&
            permissions &&
            permissions.sales.getPriceListsMargin === 'S') ||
          !priceListInformation
        "
      >
        <template slot="label">
          Porcentaje de utilidad predeterminado (Requerido)
          <b-tooltip
            multilined
            label="En caso de que un artículo no tenga un porcentaje de utilidad par esta lista de precios, se usara el predeterminado"
          >
            <b-icon
              size="is-small"
              icon="help-circle-outline"
              custom-class="input-help-icon"
            ></b-icon>
          </b-tooltip>
        </template>
        <PercentageInput
          placeholder="Ejemplo: 40"
          expanded
          icon="numeric"
          v-model="newPricesList.PORC_UTILID"
          type="number"
          step="any"
          min="1"
          required
        />
      </b-field>
    </b-field>

    <b-field grouped class="special-margin required-input">
      <b-field
        label="Usuarios permitidos (Requerido)"
        expanded
        label-position="inside"
      >
        <b-taginput
          :data="users"
          autocomplete
          :allow-new="false"
          :open-on-focus="true"
          field="email"
          icon="account"
          v-model="selectedUsers"
          @add="(value) => addUser(value)"
          @remove="(value) => removeUser(value)"
          ellipsis
          placeholder="Nombre de usuario"
          aria-close-label="Borrar usuario seleccionado"
          check-infinite-scroll
        >
        </b-taginput>
      </b-field>
    </b-field>

    <div class="special-margin">
      <b-checkbox
        class="permissions-checkbox"
        v-model="newPricesList.LISTA_PRECIOS_BASE"
      >
        Usar como lista de precios base en relación artículo proveedor
      </b-checkbox>
    </div>

    <div class="add-global-controls" v-if="!priceListInformation">
      <b-button type="is-success" @click="AddPriceList()"
        >Crear lista de precios</b-button
      >
      <b-button type="is-danger" @click="closeModal()">Regresar</b-button>
    </div>

    <div class="add-global-controls" v-if="priceListInformation">
      <b-button type="is-success" @click="updatePriceList()"
        >Actualizar lista de precios</b-button
      >

      <b-button
        type="is-danger"
        @click="
          startCancelEvent(
            'danger',
            function () {
              deletePriceList();
            },
            'borrar la lista de precios'
          )
        "
        >Borrar lista de precios</b-button
      >
    </div>
  </div>
</template>

<script>
import { openConfirmationModalDanger } from "../../../utils/component-fns";
import { validateFields, checkValidationErrors } from "../../../utils/fns";
// Cleave variable initialization to bind formats to inputs
const cleave = {
  name: "cleave",
  bind(el, binding) {
    const input = el.querySelector("input");
    input._vCleave = new Cleave(input, binding.value);
  },
  unbind(el) {
    const input = el.querySelector("input");
    input._vCleave.destroy();
  },
};

import Cleave from "cleave.js";
import { fillObjectForm } from "../../../utils/component-fns";
// @ is an alias to /src
export default {
  name: "AddPricesListModal",
  props: ["priceListInformation"],
  components: {},
  directives: { cleave },
  data() {
    return {
      typeSelected: [],
      newPricesList: {
        CLAVE_LISTAP: "",
        DESCRIPCION: "",
        PORC_UTILID: 40,
        USUARIOS: [],
        LISTA_PRECIOS_BASE: false,
      },
      selectedUsers: [],
      pricesListRequiredErrors: {
        CLAVE_LISTAP: false,
        PORC_UTILID: false,
      },
    };
  },
  mounted() {
    // Fill the prices list information in form fields
    if (this.priceListInformation) {
      // Fill the form with received information
      fillObjectForm(this.priceListInformation, this.newPricesList);
      this.newPricesList._id = this.priceListInformation._id;
    }

    if (this.priceListInformation.USUARIOS.length > 0) {
      for (const singlePriceListUser of this.priceListInformation.USUARIOS) {
        for (const singleUser of this.users) {
          if (singlePriceListUser === singleUser._id) {
            this.selectedUsers.push(singleUser);
          }
        }
      }
    }
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    /**
     * @desc saves a new price list in the database, display messages according to the status
     */
    async AddPriceList() {
      try {
        this.pricesListRequiredErrors = validateFields(
          this.newPricesList,
          this.pricesListRequiredErrors
        );
        if (checkValidationErrors(this.pricesListRequiredErrors)) {
          let response = await this.$store.dispatch(
            "SAVEPRICELIST",
            this.newPricesList
          );
          if (response == "Success") {
            this.$buefy.dialog.confirm({
              title: "Éxito",
              message: "Se guardó exitosamente la nueva lista de precios",
              confirmText: "Entendido",
              type: "is-success",
              hasIcon: true,
              onConfirm: () => {
                this.closeModal();
              },
              cancelText: "cancelar",
              canCancel: false,
            });
          } else {
            this.$buefy.dialog.confirm({
              title: "Error",
              message:
                "Hubo un error al guardar la lista de precios en la base de datos",
              confirmText: "Entendido",
              type: "is-danger",
              hasIcon: true,
              cancelText: "cancelar",
              canCancel: false,
            });
          }
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al guardar la lista de precios en la base de datos revise los campos obligatorios",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message:
            "Hubo un error al guardar la lista de precios en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    /**
     * @desc upsates a price list in the database, display messages according to the status
     */

    async updatePriceList() {
      try {
        this.pricesListRequiredErrors = validateFields(
          this.newPricesList,
          this.pricesListRequiredErrors
        );
        if (checkValidationErrors(this.pricesListRequiredErrors)) {
          let response = await this.$store.dispatch(
            "EDITPRICELIST",
            this.newPricesList
          );
          if (response == "Success") {
            this.$buefy.dialog.confirm({
              title: "Éxito",
              message: "Se actualizó exitosamente la lista de precios",
              confirmText: "Entendido",
              type: "is-success",
              hasIcon: true,
              onConfirm: () => {
                this.closeModal();
              },
              cancelText: "cancelar",
              canCancel: false,
            });
          } else {
            this.$buefy.dialog.confirm({
              title: "Error",
              message:
                "Hubo un error al actualizar la lista de precios en la base de datos",
              confirmText: "Entendido",
              type: "is-danger",
              hasIcon: true,
              cancelText: "cancelar",
              canCancel: false,
            });
          }
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al actualizar la lista de precios en la base de datos revise los campos obligatorios",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message:
            "Hubo un error al actualizar la lista de precios en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    /**
     *
     * @desc calls external function to open action confirmation modal
     * @param type the type of the dialog
     * @param functionOnConfirm the function tht will be run after the confirmation success
     * @param dangerMessage the message to display to the user
     */
    startCancelEvent(type, functionOnConfirm, dangerMessage) {
      // Call external function that opens confirmation dialog and pass the context to the function
      openConfirmationModalDanger.call(
        this,
        type,
        functionOnConfirm,
        dangerMessage
      );
    },
    async deletePriceList() {
      try {
        let response = await this.$store.dispatch(
          "DELETERICELIST",
          this.newPricesList
        );
        if (response == "Success") {
          this.$buefy.dialog.confirm({
            title: "Éxito",
            message: "Se borró exitosamente la lista de precios",
            confirmText: "Entendido",
            type: "is-success",
            hasIcon: true,
            onConfirm: () => {
              this.closeModal();
            },
            cancelText: "cancelar",
            canCancel: false,
          });
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al borrar la lista de precios en la base de datos",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message:
            "Hubo un error al borrar la lista de precios en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    addUser(user) {
      this.newPricesList.USUARIOS.push(user._id);
    },
    removeUser(user) {
      for (const [
        singleUserIndex,
        singleUser,
      ] of this.newPricesList.USUARIOS.entries()) {
        if (singleUser === user._id) {
          this.newPricesList.USUARIOS.splice(singleUserIndex, 1);
          return;
        }
      }
    },
  },
  computed: {
    companyPaymentPlanModules() {
      if (
        this.$store.getters.COMPANY_INFORMATION &&
        this.$store.getters.COMPANY_INFORMATION.paymentPlan
      ) {
        return this.$store.getters.COMPANY_INFORMATION.paymentPlan.modules;
      }
      return this.$store.getters.COMPANY_INFORMATION.paymentPlan.modules;
    },
    permissions() {
      if (this.$store.getters.USER.permissions) {
        return this.$store.getters.USER.permissions.permissions;
      }
      return this.$store.getters.USER.permissions;
    },
    users() {
      return this.$store.getters.USERS_ALL.map((singleUser) => {
        const userWithProps = { ...singleUser };
        userWithProps.permissionsName = userWithProps.permissions.name;
        if (userWithProps.STATUS === "AC") {
          userWithProps.STATUS_FOR = "Activo";
        }
        if (userWithProps.STATUS === "PE") {
          userWithProps.STATUS_FOR = "Desactivado";
        }
        return userWithProps;
      });
    },
  },
  watch: {
    users() {
      if (this.priceListInformation.USUARIOS.length > 0) {
        for (const singlePriceListUser of this.priceListInformation.USUARIOS) {
          for (const singleUser of this.users) {
            if (singlePriceListUser === singleUser._id) {
              this.selectedUsers.push(singleUser);
            }
          }
        }
      }
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
